<template>
  <v-main>
    <v-container fluid>
      <!-- <v-row v-if="showNoTickets && !loading" align="center" justify="center">
        <v-col cols="6" md="4" lg="2">
          <v-card class="text-center pa-5">
            <v-icon color="primary" x-large>mdi-account-details</v-icon>
            <div class="headline mt-2">No tickets available</div>
            <v-btn @click="handleNewTicket" color="primary" class="mt-4"
              ><v-icon class="mr-2">mdi-plus-circle</v-icon> New ticket</v-btn
            >
          </v-card>
        </v-col>
      </v-row> -->

      <v-row v-if="loading" align="center" justify="center" class="mt-12">
        <v-progress-circular
          :size="55"
          :width="4"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>

      <v-row v-if="!loading">
        <v-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
          :style="{ position: isFixedStyleActive }"
        >
          <v-card class="message-viewer--size">
            <template>
              <v-list subheader two-line>
                <v-list-item>
                  <v-list-item-content>
                    <v-form @submit.prevent="submitSearch">
                      <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                        color="primary"
                      />
                    </v-form>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title>
                      <v-row fluid class="mt-2">
                        <v-col cols="6" class="pb-0">
                          <v-select
                            class="message-viewer--filter"
                            v-model="filterSelected"
                            :items="filterOptions"
                            item-text="name"
                            item-value="value"
                            label="Message type"
                            @change="replaceRoutePath($event, $data, $props)"
                            color="primary"
                          ></v-select>
                        </v-col>

                        <v-col cols="6" class="pb-0">
                          <v-select
                            class="message-viewer--filter"
                            v-model="statusSelected"
                            :items="statusOptions"
                            item-text="name"
                            item-value="value"
                            label="Status"
                            @change="replaceRoutePath($event, $data, $props)"
                            color="primary"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-card-actions class="pt-2 mb-2" v-if="false">
                  <v-btn @click="handleNewTicket" text color="primary">
                    <v-icon class="mr-2">mdi-android-messages</v-icon>New
                    Message
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
                <template v-if="!ticketList.length">
                  <v-card flat class="text-center pa-5">
                    <div class="headline">
                      <v-icon large color="black" class="mr-2"
                        >mdi-magnify</v-icon
                      >No messages
                    </div>
                  </v-card>
                </template>
                <template v-else>
                  <v-list-item-group
                    v-model="selected"
                    active-class="blue--text"
                    color="primary"
                    mandatory
                    :key="selected"
                  >
                    <div
                      v-for="(thread, index) in ticketList"
                      :key="thread.uuid"
                    >
                      <v-list-item
                        @click="openThread(thread.uuid)"
                        :id="thread.uuid"
                        color="primary"
                        v-intersect.quiet="{
                          handler: infiniteScrolling,
                        }"
                      >
                        <v-list-item-content v-if="true">
                          <v-list-item-title class="mb-4"
                            >Message
                            {{ thread.ticket_number }}
                            <span class="float-right" v-if="!thread.resolved"
                              ><v-icon>mdi-email-mark-as-unread</v-icon></span
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="!!thread.creator"
                            ><b>Customer:</b>
                            {{ thread.creator.username }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle v-if="!!thread.device"
                            >Device serial:
                            {{ thread.device.name }}</v-list-item-subtitle
                          >
                          <!-- <v-list-item-subtitle v-if="!!thread.partner"
                            ><b>Dealer:</b>
                            {{ thread.partner.name }}</v-list-item-subtitle
                          > -->
                          <v-list-item-subtitle
                            class="text-capitalize"
                            v-if="!!thread.type"
                            >Type:
                            {{
                              thread.type
                                .toLowerCase()
                                .split("_")
                                .join(" ")
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-icon v-if="thread.active">
                          <v-icon color="primary">mdi-chat</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider
                        v-if="index + 1 < ticketList.length"
                        :key="index"
                      ></v-divider>
                    </div>
                  </v-list-item-group>
                </template>
              </v-list>
            </template>
          </v-card>
        </v-col>

        <!-- CONTENT -->
        <v-col
          cols="12"
          sm="12"
          md="9"
          lg="9"
          xl="9"
          offset-lg="3"
          offset-md="3"
          offset-xl="3"
        >
          <router-view
            @update="triggerUpdate"
            :trigger-update="triggerUpdateKey"
            :initial-data="lastMessageThread"
            :no-thread="threadAvailable"
            :key="forceUpdate"
          ></router-view>
        </v-col>
      </v-row>
    </v-container>
    <crm-ticket-add
      v-if="newTicketDialog.active"
      :dialog="newTicketDialog"
      @close="closeNewTicketDialog"
      @saved="handleNewTicketSaved"
    ></crm-ticket-add>
  </v-main>
</template>

<script>
import api from "@/utils/api.js";
import debounce from "@/plugins/debounce";
import CrmTicketAdd from "@/components/dialogs/CrmTicketAdd";

export default {
  name: "Messages",
  components: {
    CrmTicketAdd,
  },
  data: () => ({
    loading: true,
    selected: null,
    ticketList: [],
    isActive: false,
    counter: 0,
    getUuid: null,
    nextUrl: null,
    search: null,
    lastMessageThread: {},
    forceUpdate: 0,
    searchMessage: "",
    filterSelected: "All",
    statusSelected: "All",
    showNoTickets: false,
    isFixedStyleActive: "fixed",
    newTicketDialog: { active: false },
    triggerUpdateKey: 0,
    filterOptions: [],
    statusOptions: [],
  }),

  created() {
    this.setInitialData();

    if (window.innerWidth < 960) {
      this.isFixedStyleActive = "relative";
    }
    if (
      (this.$route.query.type && this.$route.query.status) ||
      typeof this.$route.params.id !== "undefined"
    ) {
      this.filterSelected = this.$route.query.type || "All";
      this.statusSelected = this.$route.query.status || "All";
      this.search = this.$route.query.search || "";
      this.replaceRoutePath(null, this);
    } else {
      this.loadLastThread();
    }
  },

  watch: {
    $route(val) {
      if (val.name === "messages") {
        this.selected = 0;
        this.loadLastThread();
      }
    },
    selected(val) {
      this.triggerUpdateKey++;
      if (val === -1 && typeof this.ticketList[0] !== "undefined") {
        this.getLastAvailableMessage();
      }
    },
  },

  computed: {
    threadAvailable() {
      return this.ticketList.length > 0;
    },
  },

  mounted() {
    window.onresize = () => {
      if (window.innerWidth < 960) {
        this.isFixedStyleActive = "relative";
      } else {
        this.isFixedStyleActive = "fixed";
      }
    };
  },

  methods: {
    submitSearch(event) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          search: this.search,
        },
      });

      this.loadSearch(event, this, null, this.$route);
    },
    setInitialData() {
      this.filterOptions = [
        {
          name: "All",
          value: "All",
        },
        {
          name: "Support request",
          value: "SUPPORT_REQUEST",
        },
        {
          name: "Service request",
          value: "SERVICE_REQUEST",
        },
        {
          name: "Security request",
          value: "SECURITY_REQUEST",
        },
      ];

      this.statusOptions = [
        {
          name: "All",
          value: "All",
        },
        {
          name: "Active",
          value: "True",
        },
        {
          name: "Closed",
          value: "False",
        },
      ];
    },

    loadLastThread() {
      let promises = [
        api.getLastMessageThread(),
        api.getTicketList({ open: "All" }),
      ];
      Promise.all(promises).then((values) => {
        this.getLastMessage(values[0]);
        this.loadTickets(values[1]);
      });
    },
    getLastAvailableMessage() {
      this.$router.replace({
        name: "MessagesThread",
        params: {
          id: this.ticketList[0].uuid,
        },
        query: {
          type: this.filterSelected,
          status: this.statusSelected,
          search: this.search,
        },
      });

      api.getTicket(this.ticketList[0].uuid).then((resp) => {
        this.lastMessageThread = resp.data;
        this.selected = 0;
        this.forceUpdate++;
      });
    },
    getLastMessage(resp) {
      this.filterSelected = "All";
      this.statusSelected = "All";
      this.lastMessageThread = resp.data;
      this.$router.replace({
        name: "MessagesThread",
        params: {
          id: resp.data.uuid,
        },
        query: {
          type: this.filterSelected,
          status: this.statusSelected,
          search: this.search,
        },
      });
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (entries[0].target.id === this.getUuid) {
        let path = this.nextUrl
          .split("/")
          .splice(5, 5)
          .join("/");

        api.getTicketNextPage(path).then((resp) => {
          this.getNextPage(resp);
          resp.data.results.forEach((element) => {
            this.ticketList.push(element);
          });
          this.handleSelectedMessage();
        });
      }
    },
    triggerUpdate(newThread) {
      let ticket = this.ticketList.filter((x) => x.uuid === newThread.uuid)[0];
      ticket.resolved = newThread.resolved;
    },

    loadTickets(resp) {
      resp.data.results.length === 0
        ? (this.showNoTickets = true)
        : (this.showNoTickets = false);
      this.getNextPage(resp);
      this.ticketList = resp.data.results;
      this.handleSelectedMessage();
      this.loading = false;
    },

    openThread(threadUUID) {
      this.$router.push({
        name: "MessagesThread",
        params: {
          id: threadUUID,
        },
        query: {
          type: this.filterSelected,
          status: this.statusSelected,
        },
      });
    },

    getNextPage(resp) {
      if (resp.data.next) {
        this.getUuid = resp.data.results[resp.data.results.length - 2].uuid;
        this.nextUrl = resp.data.next;
      } else {
        this.getUuid = null;
      }
    },

    handleSelectedMessage() {
      if (this.$route.params.id) {
        // Mark current thread as selected
        let thread = this.ticketList.find((thread) => {
          return thread.uuid === this.$route.params.id;
        });
        let index = this.ticketList.indexOf(thread);
        this.selected = index;
      } else if (this.ticketList.length) {
        // Open the first messages thread automatically
        this.selected = 0;
        this.$router.replace({
          name: "MessagesThread",
          params: {
            id: this.ticketList[0].uuid,
          },
          query: {
            type: this.filterSelected,
            status: this.statusSelected,
            search: this.search,
          },
        });
      }
    },

    handleNewTicket() {
      this.newTicketDialog = {
        active: true,
      };
    },
    handleNewTicketSaved() {
      this.newTicketDialog = { active: false };

      api.getTicketList({ open: "All" }).then((resp) => {
        let promise = new Promise((resolve, reject) => {
          this.loadTickets(resp);
          this.getLastAvailableMessage();
          resolve();
        });
        promise.then((resp) => {
          this.selected = 0;
        });
      });
    },
    closeNewTicketDialog() {
      this.newTicketDialog = { active: false };
    },

    replaceRoutePath(event, self, props) {
      this.$router.replace({
        name: "MessagesThread",
        params: {
          id: this.$route.params.id,
        },
        query: {
          type: this.filterSelected,
          status: this.statusSelected,
          search: this.search,
        },
      });
      this.loadSearch(event, self, props, this.$route);
    },

    loadSearch: debounce((event, self, props, route) => {
      self.loading = true;
      self.search = self.search ? self.search : "";
      let filterSelected =
        self.filterSelected !== "All" ? self.filterSelected : "";
      let statusSelected =
        self.statusSelected !== "All" ? self.statusSelected : "";
      api
        .getTicketList({
          search: self.search,
          type: filterSelected,
          open: statusSelected,
        })
        .then((resp) => {
          self.ticketList = [];

          if (resp.data.next) {
            self.getUuid = resp.data.results[resp.data.results.length - 2].uuid;
            self.nextUrl = resp.data.next;
          } else {
            self.getUuid = null;
          }

          resp.data.results.forEach((element) => {
            self.ticketList.push(element);
          });

          let thread = self.ticketList.find((thread) => {
            return thread.uuid === route.params.id;
          });

          let index = -1;
          if (typeof thread !== "undefined") {
            index = self.ticketList.indexOf(thread);
          }

          self.selected = index;
          self.loading = false;
        });
    }, 300),
  },
};
</script>
<style scoped>
.message-viewer--size {
  overflow-y: scroll;
  height: 90vh;
}
.message-viewer--filter {
  margin-top: 0px;
  padding-top: 0px;
}
.message-viewer-no-ticket--position {
  width: 50%;
  margin: auto;
}
message-viewer--fixed {
  position: fixed;
}
</style>
