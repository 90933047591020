import store from "@/store";
import { USER_CRM_REQUEST } from "@/store/actions/user";

const boatRules = [
  {
    rule: "slot",
    handler: [(v) => !!v || "Slot number is required"],
    label: "Slot number*",
    required: true,
  },
  {
    rule: "model",
    handler: [(v) => !!v || "Model is required"],
    label: "Select boat model*",
    required: true,
  },
  {
    rule: "hin",
    handler: [(v) => !!v || "HIN is required"],
    label: "HIN*",
    required: true,
  },
  {
    rule: "sentinelSN",
    handler: [(v) => !!v || "Sentinel Serial is required"],
    label: "Sentinel SN*",
    required: true,
  },
];

let canUseCrmFeature = (feature) => {
  return !!store.getters.getMe.features[feature];
};

let isCrmGroupSuperGroup = (groupUuid) => {
  let group = store.getters.getMe.crm_groups.find((group) => {
    return group.uuid === groupUuid;
  });
  if (typeof group === "undefined") return null;

  return group.is_supergroup;
};

let canUseCrmGroupFeature = (canUsefeature, groupUUid) => {
  let group = store.getters.getMe.crm_groups.find((group) => {
    return group.uuid === groupUUid;
  });
  if (typeof group === "undefined") return false;

  let feature = group.features.find((feature) => {
    return (
      feature.name === canUsefeature && feature.value.toLowerCase() === "true"
    );
  });

  if (typeof feature === "undefined") return false;

  return true;
};

let getBoatRules = () => {
  if (
    typeof store.getters.getMe.features.enum_required_boat_fields ===
    "undefined"
  )
    return boatRules;

  const rules =
    store.getters.getMe.features.enum_required_boat_fields.split(",");

  return boatRules.map((rule) => {
    return {
      ...rule,
      required: rules.includes(rule.rule),
      label: rules.includes(rule.rule) ? rule.label : rule.label.slice(0, -1),
      handler: rules.includes(rule.rule) ? rule.handler : [],
    };
  });
};

let canUseUserFeature = (feature) => {
  const setFeature = store.getters.getMe.user.features.find(
    (element) => element.name === feature
  );

  if (typeof setFeature === "undefined") return false;

  return setFeature.value.toLowerCase() === "true";
};

let canUserUseRefreshBoat = (group) => {
  const setGroup = store.getters.getMe.crm_groups.find(
    (element) => element.uuid === group.uuid
  );

  if (typeof setGroup === "undefined") return false;

  return setGroup.roles.some((element) => {
    return element.clear_boat_permission;
  });
};

let canUserCreateNewArticle = () => {
  return new Promise((resolve, reject) => {
    store.dispatch(USER_CRM_REQUEST).then((resp) => {
      resolve(store.getters.getMe.menu_access.newsfeed.can_create_new);
    });
  });
};

const canUserSeeGroupDetails = (group) => {
  if (
    group.groups_permission === "WRITE" ||
    group.groups_permission === "READ"
  ) {
    return true;
  }
  return false;
};

const canUserAddEditGroupDetails = (group) => {
  if (group.groups_permission === "WRITE") {
    return true;
  }
  return false;
};

const nextBaseRoute = (getMe) => {
  const priorityRoutesList = [
    "dashboards",
    "boats",
    "newsfeed",
    "tasks",
    "groups",
    "data",
    "messages",
    "new-user",
  ];

  if (!store.getters.getTosAccepted) {
    return { name: "termsOfService" };
  }
  if (!store.getters.getTosPartnerAccepted) {
    return { name: "consents" };
  }

  const userVisibleTabs = Object.keys(getMe.menu_access).filter((key) => {
    return getMe.menu_access[key].visible === true;
  });

  const nextPriorityRoute = priorityRoutesList.filter((route) =>
    userVisibleTabs.includes(route)
  );
  if (nextPriorityRoute.length === 0) {
    return { name: "new-user" };
  }

  return { name: nextPriorityRoute[0] };
};

export default {
  canUseCrmFeature,
  canUseCrmGroupFeature,
  getBoatRules,
  canUseUserFeature,
  canUserUseRefreshBoat,
  canUserCreateNewArticle,
  nextBaseRoute,
  canUserSeeGroupDetails,
  canUserAddEditGroupDetails,
  isCrmGroupSuperGroup,
};
