<template>
  <v-bottom-sheet v-model="dialog.active" persistent>
    <v-sheet color="grey lighten-5" class="justify-center" min-height="400px">
      <!-- <v-card-title class="ml-3">New Ticket</v-card-title> -->
      <v-container>
        <v-row class="justify-center">
          <v-col cols="12" xl="8" lg="8" md="10" sm="12">
            <v-card class="px-4 py-1">
              <v-row no-gutters class="justify-space-between">
                <v-col cols="6" xl="6" lg="6" md="6" sm="6">
                  <v-select
                    class="mr-2"
                    prepend-icon="mdi-account"
                    :items="userOptions"
                    v-model="editedItem.recipient_type"
                    label="Recipient"
                    item-text="title"
                    item-value="value"
                    :disabled="multiDropdownDisabled"
                  ></v-select>
                </v-col>
                <v-col
                  cols="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  v-if="
                    editedItem.recipient_type === 'CRM_BOAT' &&
                      crmPartnersOptions.length === 1
                  "
                >
                  <v-select
                    class="ml-2"
                    prepend-icon="mdi-sail-boat"
                    :items="boatOwnerOptions"
                    v-model="editedItem.crm_boat_uuid"
                    label="Select Boat"
                    item-value="uuid"
                    item-disabled="is_user_present"
                  >
                    <template v-slot:item="{ item }">
                      <span>
                        {{ item.boat_name }}
                      </span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>
                        {{ item.boat_name }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  v-if="
                    (editedItem.recipient_type === 'CRM_BOAT' ||
                      editedItem.recipient_type === 'PARTNER') &&
                      crmPartnersOptions.length > 1
                  "
                >
                  <v-select
                    class="ml-2"
                    prepend-icon="mdi-account-group"
                    :items="crmPartnersOptions"
                    v-model="editedItem.sender_partner_uuid"
                    label="Select Partner"
                    item-value="uuid"
                    item-text="name"
                    @change="
                      handleChangeDealership(editedItem.sender_partner_uuid)
                    "
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  v-if="
                    editedItem.recipient_type === 'CRM_BOAT' &&
                      crmPartnersOptions.length > 1
                  "
                >
                  <v-select
                    prepend-icon="mdi-sail-boat"
                    :items="boatOwnerOptions"
                    v-model="editedItem.crm_boat_uuid"
                    label="Select Boat"
                    item-value="uuid"
                    item-disabled="is_user_present"
                  >
                    <template v-slot:item="{ item }">
                      <span>
                        {{ item.boat_name }}
                      </span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>
                        {{ item.boat_name }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="6"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  v-if="editedItem.recipient_type === 'PARTNER'"
                >
                  <assignee-selector
                    class="mr-2"
                    :initial-assignee-uuid="''"
                    :prepend-icon="'mdi-account'"
                    :dealership-uuid="editedItem.sender_partner_uuid"
                    :key="updateAssignee"
                    @change="handleAssigneeSelectorChange"
                  ></assignee-selector>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" xl="8" lg="8" md="10" sm="12">
            <v-card class="px-4 py-1">
              <v-col
                cols="6"
                class="pl-0 pb-0 pt-2"
                v-if="editedItem.recipient_type !== 'CRM_BOAT'"
              >
                <v-select
                  prepend-icon="mdi-sail-boat"
                  :items="boatOwnerOptions"
                  v-model="editedItem.crm_boat_uuid"
                  label="Select Boat"
                  item-value="uuid"
                  item-disabled="is_user_present"
                >
                  <template v-slot:item="{ item }">
                    <span>
                      {{ item.boat_name }}
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>
                      {{ item.boat_name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-textarea
                v-model="editedItem.content"
                prepend-icon="mdi-note-text"
                label="Message"
                auto-grow
              ></v-textarea>
              <direct-file-upload
                :files="files"
                label="Attachments"
                :prepend-icon="'mdi-attachment'"
                :asset-type="'image_asset'"
                :disable-preview="true"
                ref="directFileUpload"
                @saved="handleDirectUploadFiles"
              ></direct-file-upload>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="12" xl="8" lg="8" md="10" sm="12">
            <v-card-actions class="py-2 px-0">
              <v-btn class="mt-2 mr-2" text color="red" @click="close">
                Close
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                class="mt-2 ml-2"
                color="primary"
                @click="save"
              >
                Send
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import restrictions from "@/utils/restrictions";
import DirectFileUpload from "@/components/miscellaneous/DirectFileUpload.vue";
import AssigneeSelector from "@/components/selectors/AssigneeSelector";
import api from "@/utils/api";
import { mapGetters } from "vuex";
export default {
  components: {
    DirectFileUpload,
    AssigneeSelector,
  },
  props: {
    dialog: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    loading: false,
    boatOwnerOptions: [],
    crmPartners: [],
    updateAssignee: 0,
    editedItem: {
      title: "",
      content: "",
      crm_boat_uuid: null,
      send_email: true,
      recipient_type: "CRM_BOAT",
      sender_partner_uuid: null,
      recipient_partner_uuid: null,
      attachment_uuids: [],
      primary_recipient: null,
      last_message_uuid: null,
    },
    files: [],
    note: "",
    userOptions: [],
  }),

  computed: {
    ...mapGetters(["getMe"]),
    multiDropdownDisabled: () => {
      return !restrictions.canUseCrmFeature("can_new_message_for_team");
    },
  },

  created() {
    this.setInitalData();
    api.getLastMessageThread().then((resp) => {
      this.editedItem.last_message_uuid = resp.data.uuid;
    });

    this.crmPartnersOptions = this.getMe.dealerships || [];
    if (this.crmPartnersOptions.length !== 0) {
      this.getPartnerBoats(this.crmPartnersOptions[0].uuid);
    }
    this.$set(
      this.editedItem,
      "sender_partner_uuid",
      this.crmPartnersOptions[0].uuid
    );
  },

  methods: {
    setInitalData() {
      this.userOptions = [
        {
          title: "Boat owner",
          value: "CRM_BOAT",
        },
        // { title: "Sentinel Technical Support", value: "SENTINEL_SUPPORT" },
        {
          title: "Team member",
          value: "PARTNER",
        },
        // { title: "Partner", value: "NOTE" },
      ];
    },
    handleChangeDealership(partner) {
      this.getPartnerBoats(partner);
      this.$set(this.editedItem, "crm_partner_uuid", partner);
    },
    getPartnerBoats(partner) {
      api.getCrmPartnerBoats(partner).then((resp) => {
        this.boatOwnerOptions = resp.data.map((element) => {
          return { ...element, is_user_present: !element.is_user_present };
        });
        this.updateAssignee++;
      });
    },
    handleAssigneeSelectorChange(event) {
      this.editedItem.primary_recipient = event.username;
    },
    close() {
      this.$emit("close", true);
    },
    save() {
      if (this.crm_boat_uuid === "") {
        this.crm_boat_uuid = null;
      }

      if (this.editedItem.recipient_type === "PARTNER") {
        this.editedItem.recipient_partner_uuid = this.editedItem.sender_partner_uuid;
      } else {
        delete this.editedItem.recipient_partner_uuid;
      }

      if (this.editedItem.recipient_type === "PARTNER") {
        this.editedItem.send_email = false;
      } else {
        this.editedItem.primary_recipient = null;
      }

      this.loading = true;
      this.$refs.directFileUpload.save().then((resp) => {
        this.handleDirectUploadFiles(resp);
        api
          .setTicket(this.editedItem)
          .then((resp) => {
            this.$emit("saved", true);
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },

    handleDirectUploadFiles(uuid) {
      const { file_resource_uuids, fileUuids } = uuid;
      this.editedItem.attachment_uuids = file_resource_uuids || fileUuids;
    },
  },
};
</script>
<style scoped>
.v-select.v-input--is-dirty input {
  display: none;
}

.v-select__selection {
  max-width: none;
}
</style>
