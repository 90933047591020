var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{attrs:{"persistent":""},model:{value:(_vm.dialog.active),callback:function ($$v) {_vm.$set(_vm.dialog, "active", $$v)},expression:"dialog.active"}},[_c('v-sheet',{staticClass:"justify-center",attrs:{"color":"grey lighten-5","min-height":"400px"}},[_c('v-container',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","xl":"8","lg":"8","md":"10","sm":"12"}},[_c('v-card',{staticClass:"px-4 py-1"},[_c('v-row',{staticClass:"justify-space-between",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-select',{staticClass:"mr-2",attrs:{"prepend-icon":"mdi-account","items":_vm.userOptions,"label":"Recipient","item-text":"title","item-value":"value","disabled":_vm.multiDropdownDisabled},model:{value:(_vm.editedItem.recipient_type),callback:function ($$v) {_vm.$set(_vm.editedItem, "recipient_type", $$v)},expression:"editedItem.recipient_type"}})],1),(
                  _vm.editedItem.recipient_type === 'CRM_BOAT' &&
                    _vm.crmPartnersOptions.length === 1
                )?_c('v-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-select',{staticClass:"ml-2",attrs:{"prepend-icon":"mdi-sail-boat","items":_vm.boatOwnerOptions,"label":"Select Boat","item-value":"uuid","item-disabled":"is_user_present"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.boat_name)+" ")])]}},{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.boat_name)+" ")])]}}],null,false,1088457116),model:{value:(_vm.editedItem.crm_boat_uuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "crm_boat_uuid", $$v)},expression:"editedItem.crm_boat_uuid"}})],1):_vm._e(),(
                  (_vm.editedItem.recipient_type === 'CRM_BOAT' ||
                    _vm.editedItem.recipient_type === 'PARTNER') &&
                    _vm.crmPartnersOptions.length > 1
                )?_c('v-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-select',{staticClass:"ml-2",attrs:{"prepend-icon":"mdi-account-group","items":_vm.crmPartnersOptions,"label":"Select Partner","item-value":"uuid","item-text":"name"},on:{"change":function($event){return _vm.handleChangeDealership(_vm.editedItem.sender_partner_uuid)}},model:{value:(_vm.editedItem.sender_partner_uuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "sender_partner_uuid", $$v)},expression:"editedItem.sender_partner_uuid"}})],1):_vm._e(),(
                  _vm.editedItem.recipient_type === 'CRM_BOAT' &&
                    _vm.crmPartnersOptions.length > 1
                )?_c('v-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-sail-boat","items":_vm.boatOwnerOptions,"label":"Select Boat","item-value":"uuid","item-disabled":"is_user_present"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.boat_name)+" ")])]}},{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.boat_name)+" ")])]}}],null,false,1088457116),model:{value:(_vm.editedItem.crm_boat_uuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "crm_boat_uuid", $$v)},expression:"editedItem.crm_boat_uuid"}})],1):_vm._e(),(_vm.editedItem.recipient_type === 'PARTNER')?_c('v-col',{attrs:{"cols":"6","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('assignee-selector',{key:_vm.updateAssignee,staticClass:"mr-2",attrs:{"initial-assignee-uuid":'',"prepend-icon":'mdi-account',"dealership-uuid":_vm.editedItem.sender_partner_uuid},on:{"change":_vm.handleAssigneeSelectorChange}})],1):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","xl":"8","lg":"8","md":"10","sm":"12"}},[_c('v-card',{staticClass:"px-4 py-1"},[(_vm.editedItem.recipient_type !== 'CRM_BOAT')?_c('v-col',{staticClass:"pl-0 pb-0 pt-2",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-sail-boat","items":_vm.boatOwnerOptions,"label":"Select Boat","item-value":"uuid","item-disabled":"is_user_present"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.boat_name)+" ")])]}},{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.boat_name)+" ")])]}}],null,false,1088457116),model:{value:(_vm.editedItem.crm_boat_uuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "crm_boat_uuid", $$v)},expression:"editedItem.crm_boat_uuid"}})],1):_vm._e(),_c('v-textarea',{attrs:{"prepend-icon":"mdi-note-text","label":"Message","auto-grow":""},model:{value:(_vm.editedItem.content),callback:function ($$v) {_vm.$set(_vm.editedItem, "content", $$v)},expression:"editedItem.content"}}),_c('direct-file-upload',{ref:"directFileUpload",attrs:{"files":_vm.files,"label":"Attachments","prepend-icon":'mdi-attachment',"asset-type":'image_asset',"disable-preview":true},on:{"saved":_vm.handleDirectUploadFiles}})],1)],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","xl":"8","lg":"8","md":"10","sm":"12"}},[_c('v-card-actions',{staticClass:"py-2 px-0"},[_c('v-btn',{staticClass:"mt-2 mr-2",attrs:{"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2 ml-2",attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Send ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }