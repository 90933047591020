<template>
  <v-card
    flat
    v-cloak
    @drop.prevent="handleDirty($event.dataTransfer.files[0], true, true)"
    @dragover.prevent
  >
    <v-file-input
      v-if="!detailsView"
      color="primary"
      v-model="selectedFiles"
      :label="label"
      :multiple="multiple"
      :accept="acceptedFormats"
      :prepend-icon="prependIcon"
      @change="handleDirty(selectedFiles[0], true)"
      :key="forceUpdate"
      :rules="rules"
      :hint="hint"
      :persistent-hint="persistentHint"
    ></v-file-input>

    <v-list
      single-line
      v-if="selectedFiles.length && !disablePreview"
      :class="{ 'ml-4': marginLeft }"
      class="pt-0 mb-4"
    >
      <v-list-item v-for="(file, index) in selectedFiles" :key="index">
        <v-list-item-content class="mt-0 pt-0">
          <slot v-bind:file="file" v-if="assetType === 'image_asset'"> </slot>
          <v-list-item-subtitle v-else
            ><v-icon class="mr-2">mdi-file-document</v-icon>
            {{ file.name }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action class="mt-0 mb-0">
          <v-list-item-icon
            v-if="!detailsView"
            @click="remove(index)"
            style="cursor: pointer"
            class="mt-0 mb-0"
            ><v-icon color="red">mdi-delete</v-icon></v-list-item-icon
          >
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import api from "@/utils/api";

export default {
  props: {
    files: {
      type: Array,
      default: Array,
    },
    label: {
      type: String,
      default: "Upload images",
    },
    hint: {
      type: String,
      default: "",
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    acceptedFormats: {
      type: String,
      default: "image/*",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    prependIcon: {
      type: String,
      default: "",
    },
    assetType: {
      type: String,
      default: "document_asset",
      //asset_type options: image_asset (use only for resizable images), document_asset (use for all documents, and images you don’t need to resize)
    },
    disablePreview: {
      type: Boolean,
      default: false,
    },
    detailsView: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      selectedFiles: [],
      dirty: false,
      menu: false,
      forceUpdate: 0,
    };
  },
  created() {
    if (this.files.length === 1 && this.files[0] === null) {
      this.selectedFiles = [];
      return;
    }

    this.selectedFiles = this.files.map((file) => {
      return { ...file, name: file.title };
    });
  },
  computed: {
    marginLeft() {
      return !!this.prependIcon !== "";
    },
  },

  methods: {
    removeAll() {
      //HACK: this function is called outside of the component by $refs
      this.selectedFiles = [];
    },
    remove(index) {
      this.selectedFiles.splice(index, 1);
    },
    handleDirty(event, single = true, external = false) {
      let reader = new FileReader();
      let self = this;

      //TODO SUPPORT FOR MULITPLE FILES
      reader.onload = function () {
        if (external) {
          self.selectedFiles[0] = event;
        }
        self.selectedFiles[0].src = reader.result;
        self.$emit("updateFile", self.selectedFiles[0]);
        self.forceUpdate++;
      };

      if (typeof event === "undefined") {
        return;
      }

      if (single) {
        reader.readAsDataURL(event);
      }
      this.dirty = true;
    },
    save(files = []) {
      files = files.length === 0 ? this.selectedFiles : files;
      //Here we should retrun promise. When loading is triggered in parent, $refs object is not available (v-if="loading" on main form) so $emit will not work because it does not exists in DOM any longer.
      return new Promise((resolve, reject) => {
        let promises = files.map((element) => {
          let fileName = element.name.substring(
            0,
            element.name.lastIndexOf(".")
          );

          let fileType = element.name.substring(
            element.name.lastIndexOf(".") + 1,
            element.name.length
          );

          let data = {
            file_type: fileType,
            file_name: fileName,
            source: "PLATFORM",
            asset_type: this.assetType,
            group_uuid: element.groupUuid || null,
            categories: element.categories || null,
            languages: element.languages || null,
          };

          return new Promise((resolve, reject) => {
            api.uploadAttachments(data).then((resp) => {
              resolve({
                response: resp.data,
                file_resource_uuid: resp.data.file_resource_uuid,
                file: element,
              });
            });
          });
        });

        Promise.all(promises).then((values) => {
          let file_resource_uuids = values.map(
            (value) => value.file_resource_uuid
          );

          let promises = values.map((value) => {
            let fileData = new FormData();
            fileData.append("key", value.response.fields.key);
            fileData.append("policy", value.response.fields.policy);
            fileData.append(
              "x-amz-algorithm",
              value.response.fields["x-amz-algorithm"]
            );
            fileData.append(
              "x-amz-credential",
              value.response.fields["x-amz-credential"]
            );
            fileData.append("x-amz-date", value.response.fields["x-amz-date"]);
            fileData.append(
              "x-amz-signature",
              value.response.fields["x-amz-signature"]
            );
            fileData.append("file", value.file);

            return new Promise((resolve, reject) => {
              api
                .uploadAttachmentFile(value.response.url, fileData)
                .then((resp) => {
                  resolve({
                    file: value.file,
                    data: resp.data,
                    file_resource_uuids: file_resource_uuids,
                  });
                });
            });
          });

          Promise.all(promises).then((values) => {
            resolve({ fileUuids: file_resource_uuids });
            this.$emit("saved", { file_resource_uuids });
          });
        });
      });
    },
  },
};
</script>
<style scoped>
.border {
  border: 2px dashed orange;
}
</style>
