<template>
  <v-autocomplete
    label="Assignee"
    v-model="assignee"
    :items="assigneeOptions"
    :menu-props="{ maxHeight: '400' }"
    @input.native="loadAssignees($event, $data)"
    :search-input.sync="assigneeSearch"
    persistent-hint
    color="primary"
    clearable
    autocomplete="new-password"
    item-text="username"
    return-object
    no-filter
    :prepend-icon="prependIcon"
    @change="handleChange"
  >
    <template v-slot:item="{ item }">
      {{ item.username }}
    </template>
    <template v-slot:selection="{ item }">
      {{ item.username }}
    </template>
  </v-autocomplete>
</template>

<script>
import api from "@/utils/api";

export default {
  props: {
    initialAssigneeUuid: {
      type: String,
      default: String,
    },
    dealershipUuid: {
      type: String,
      default: String,
    },
    prependIcon: {
      type: String,
      default: String,
    },
    selectFirst: {
      type: Boolean,
      default: Boolean,
    },
  },
  data: () => ({
    assigneeOptions: [],
    assigneeSearch: "",
    timeout: null,
    assignee: {},
  }),
  created() {
    this.loadAssignees(true).then((resp) => {
      if (this.initialAssigneeUuid.length !== 0) {
        let assignee = this.assigneeOptions.find((element) => {
          return element.uuid === this.initialAssigneeUuid;
        });
        this.assignee = assignee;
      }
    });
  },

  methods: {
    handleChange(value = {}) {
      this.$emit("change", value);
    },
    loadAssignees(initalLoad = false) {
      let fetchAssignees = () => {
        return new Promise((resolve, reject) => {
          this.assigneeSearch = this.assigneeSearch ? this.assigneeSearch : "";
          if (this.dealershipUuid === null) {
            this.assigneeOptions = [];
            return;
          }
          api
            .taskAssigneeAutocomplete(this.dealershipUuid, this.assigneeSearch)
            .then((resp) => {
              this.assigneeOptions = resp.data;
              resolve();
            });
        });
      };

      if (initalLoad) return fetchAssignees();

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        return fetchAssignees();
      }, 300);
    },
  },
};
</script>
